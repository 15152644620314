var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "nav-item row" },
    [
      _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
        ? _c(
            "b-nav-item-dropdown",
            {
              staticClass: "pointer",
              attrs: {
                right: "",
                id: "entity-menu",
                "active-class": "active",
                "data-cy": "entity",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "navbar-dropdown-menu",
                  attrs: { slot: "button-content" },
                  slot: "button-content",
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "th-list" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "no-bold",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.menu.entities.management")
                        ),
                      },
                    },
                    [_vm._v("Gestion")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/cash-event" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.cashEvent")
                                ),
                              },
                            },
                            [_vm._v("Cash Event")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/catalog" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.catalog")
                                ),
                              },
                            },
                            [_vm._v("Catalog")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/illustration" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.illustration")
                                ),
                              },
                            },
                            [_vm._v("Illustration")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/product" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.product")
                                ),
                              },
                            },
                            [_vm._v("Product")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/catalog-category" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.catalogCategory")
                                ),
                              },
                            },
                            [_vm._v("Product Category")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority("ROLE_ADMIN")
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/operator" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.operator")
                                ),
                              },
                            },
                            [_vm._v("Operator")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/sale-place" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.salePlace")
                                ),
                              },
                            },
                            [_vm._v("Sale Place")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
        ? _c(
            "b-nav-item-dropdown",
            {
              staticClass: "pointer",
              staticStyle: { "margin-left": "1.5rem" },
              attrs: {
                right: "",
                id: "entity-menu",
                "active-class": "active",
                "data-cy": "entity",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "navbar-dropdown-menu",
                  attrs: { slot: "button-content" },
                  slot: "button-content",
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "database" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "no-bold",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.menu.entities.sales")
                        ),
                      },
                    },
                    [_vm._v("Opérations")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/sale" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.sale")
                                ),
                              },
                            },
                            [_vm._v("Sale")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/transaction" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.transaction")
                                ),
                              },
                            },
                            [_vm._v("Transaction")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority(["ROLE_ORGA", "ROLE_ADMIN"])
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/cash-order" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.cashOrder")
                                ),
                              },
                            },
                            [_vm._v("Cash Order")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority("ROLE_ADMIN")
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/cash-account" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.cashAccount")
                                ),
                              },
                            },
                            [_vm._v("Cash Account")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority("ROLE_ADMIN")
                    ? _c(
                        "b-dropdown-item",
                        { attrs: { to: "/cash-account-item" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "asterisk" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.cashAccountItem")
                                ),
                              },
                            },
                            [_vm._v("Cash Account Item")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }