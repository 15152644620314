import { Component, Inject, Vue } from 'vue-property-decorator';
import { ISpace } from '@/shared/model/space.model';
import SpaceService from '@/entities/space/space.service';
import axios from 'axios';

@Component({})
export default class SelectSpace extends Vue {
  @Inject('spaceService') private spaceService: () => SpaceService;
  public spaces: ISpace[] = [];

  public filters: any = {
    selectSpace: '',
  };

  created(): void {
    this.initRelationships();
  }

  public initRelationships(): void {
    axios.defaults.headers.common.Authorization = 'Bearer ${res.accessToken}';
    this.spaceService()
      .retrieveAllowSpaces()
      .then(res => {
        this.spaces = res.data.sort((a, b) => a.name.localeCompare(b.name));
        const storedSpace = JSON.parse(sessionStorage.getItem('space'));
        this.filters.selectSpace = storedSpace ? this.spaces.find(space => space.id === storedSpace.id) || this.spaces[0] : this.spaces[0];
        this.$store.commit('space', this.filters.selectSpace);
        sessionStorage.setItem('space', JSON.stringify(this.filters.selectSpace));
      });
  }

  onChange(): void {
    this.$store.commit('space', this.filters.selectSpace);
    sessionStorage.setItem('space', JSON.stringify(this.filters.selectSpace));
  }
}
