import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISpace } from '@/shared/model/space.model';

const baseApiUrl = 'api/spaces';
const baseApiAdminUrl = 'api/admin/spaces';

export default class SpaceService {
  public find(id: number): Promise<ISpace> {
    return new Promise<ISpace>((resolve, reject) => {
      axios
        .get(`${baseApiAdminUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllowSpaces(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiAdminUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiAdminUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ISpace): Promise<ISpace> {
    return new Promise<ISpace>((resolve, reject) => {
      axios
        .post(`${baseApiAdminUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ISpace): Promise<ISpace> {
    return new Promise<ISpace>((resolve, reject) => {
      axios
        .put(`${baseApiAdminUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ISpace): Promise<ISpace> {
    return new Promise<ISpace>((resolve, reject) => {
      axios
        .patch(`${baseApiAdminUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
